/* eslint-disable @typescript-eslint/no-explicit-any */
import { GetServerSideProps } from 'next';

import { PageRendererComponent, prePageCall } from '@marriott/mi-headless-utils';
import { mockModel } from '../../mocks/mockModel';
import { getContentUrl } from '@marriott/mi-leisure-components/utils';
import { NEXT_PUBLIC_ENV_KEYS, RESORT_CONTENT_ROOT } from '@marriott/mi-leisure-components/constants';
import leisureSignatures from '../../signatures/leisureSignatures.json';

function pageRenderer(props) {
  console.log('[page] props ', props);
  return <PageRendererComponent {...props} />;
}

//Any product specific page data apart from common data like session, datalayer
//can be returned from this function and the same is passed as props to the page Component
const leisureSpecificData = async (context: any) => {
  console.log('Leisure Resolved URL:', context?.resolvedUrl);
  const uri = getContentUrl(context, RESORT_CONTENT_ROOT);
  return { operationSignatures: leisureSignatures, uri: uri, nextPublicEnvKeys: NEXT_PUBLIC_ENV_KEYS };
};

//Make pre page calls like session, datalayer, fetch model.json, producSpecifc data etc
//at server side and pass the data is passed as props to page component.
export const getServerSideProps: GetServerSideProps<any> = prePageCall(leisureSpecificData, mockModel);
export default pageRenderer;
